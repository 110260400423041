import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Memoria del origen - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Memoria del origen"
        canonical="https://eduardoceballos.com/poemas/memoria-del-origen"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/memoria-del-origen",
          title: "Memoria del origen - Poemas",
          description: "Memoria del origen",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Memoria del origen</h1>
      <center>
        <p>
          Vuelvo con la memoria al origen,
          <br />
          al primer momento
          <br />
          cuando el jugo del asombro
          <br />
          dibujaba mi ser
          <br />
          en esa alquimia jubilosa
          <br />
          por donde va la vida
          <br />
          construyéndose.
          <br />
          <br />
          Desde ese silencio de milagro
          <br />
          viene dando vueltas este mundo mío,
          <br />
          que me pertenece,
          <br />
          que viaja conmigo por el tiempo
          <br />y me vincula con todos los espacios.
          <br />
          <br />
          Desde esa intimidad
          <br />
          salgo a visitar el cosmos
          <br />
          donde viven los hombres
          <br />
          con sus urgencias.
          <br />
          <br />
          En el esqueleto
          <br />
          fueron creciendo las palabras
          <br />y el mensaje cruza los océanos,
          <br />
          se mete en las casas
          <br />
          donde otros hombres
          <br />
          trabajan por sus causas.
          <br />
          <br />
          Una poesía de imágenes
          <br />
          crea los paisajes,
          <br />
          por donde pasa el poeta
          <br />
          con sus emociones nuevas
          <br />
          moviendo inteligencia y sensibilidad
          <br />
          para retornar a la memoria del origen.
        </p>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
